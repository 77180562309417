<template>
  <div>
    <v-card-text class="my-5 d-flex justify-space-between align-center pa-4">
      <p class="text-h6">Ответственный: {{ responsible_user }}</p>
    </v-card-text>
    <v-card class="my-5">
      <v-card-text>
        <v-card-actions class="px-0">
          <div class="views-history text-body-1 text-sm-h5 black--text">
            Визиты
          </div>
          <v-spacer></v-spacer>
          <v-card elevation="0" class="d-flex align-center">
            <v-card-text
              class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
            >
              4 сессии
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <v-card elevation="0" class="d-flex align-center">
            <v-card-text
              class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
            >
              {{ (patient.visits || []).length }} визитов
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            style="background-color: #d86568; height: 50px; border-radius: 50px"
            @click="showSessionDialog"
          >
            {{
              this.hasHoursPassed(this.patient.timeout)
                ? "Начать просмотр"
                : this.remainingTime
            }}
          </v-btn>
        </v-card-actions>
        <object-grid
          :content_type="content_type"
          :value="patient.visits || []"
          :hide-toolbar="true"
          :show-actions="this.showActions"
          :field-defs="[
            { text: 'Сессия', value: 'product_name' },
            { text: 'Дата', value: 'date' },
          ]"
          @doEditItem="showSessionDialog"
        />
      </v-card-text>
    </v-card>
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>
<script>
import ObjectGrid from "@/components/ObjectGrid.vue";
import { mapActions, mapGetters } from "vuex";

import SessionForm from "@/components/SessionForm.vue";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";

export default {
  name: "PatientView",
  components: { ObjectGrid, ErrorMessageWrapper },
  data() {
    return {
      content_type: "products",
      showActions: { edit: false },
      checkSessionInterval: undefined,
      openedSession: undefined,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["configProperty"]),
    ...mapGetters("users", ["users"]),
    patient() {
      const item = this.user.Patient;
      return {
        ...(item || {}),
        age: item?.data?.age,
        comment: item?.data?.comment,
        problem: item?.data?.problem,
        condition: item?.data?.condition,
        review: item?.data?.review,
        visits: item?.data?.visits?.active
          ? item.data.visits.active.sort((a, b) => {
              const extractSessionNumber = (productName) => {
                const match = productName.match(/Сессия\s*(\d+)/);
                return match ? parseInt(match[1], 10) : Infinity; // Если не найден, возвращаем Infinity
              };

              const dateA = new Date(a.date).getTime();
              const dateB = new Date(b.date).getTime();

              if (dateA !== dateB) {
                return dateA - dateB; // Сортировка по дате (более старая дата впереди)
              }

              const sessionA = extractSessionNumber(a.product_name);
              const sessionB = extractSessionNumber(b.product_name);
              return sessionA - sessionB; // Сортировка по номеру сессии
            })
          : undefined,
        timeout: item?.data?.timeout
          ? new Date(item?.data?.timeout)
          : undefined,
      };
    },
    responsible_user() {
      const user = this.users.find(
        (user) => user.id === this.user.Patient.user_id
      );
      return user ? user.first_name + " " + user.last_name : "";
    },
    categorySessionsMap() {
      const resultMap = {};
      this.patient?.visits?.forEach((visit) => {
        // Обработка только visits у которых есть category_id и session_number
        // нужно для обратной совместимости с старыми сессиями (20.06.24)
        if (visit.category_id && visit.session_number) {
          const minimumSessionNumber = resultMap[visit.category_id] ?? Infinity;
          if (visit.session_number < minimumSessionNumber) {
            resultMap[visit.category_id] = visit.session_number;
          }
        }
      });

      return resultMap;
    },
    remainingTime() {
      if (!this.patient.timeout) return null;
      const currentTime = Date.now();
      const timeDifference = currentTime - this.patient.timeout.getTime();
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      const requiredHours = this.configProperty("patientSessionTimeout");
      if (hoursDifference >= requiredHours) return null;
      const remainingHours = requiredHours - hoursDifference;
      const remainingMinutes = Math.floor((remainingHours % 1) * 60);
      return `${Math.floor(remainingHours)} ч ${remainingMinutes} мин`;
    },
  },
  methods: {
    ...mapActions("patients", ["request_patients", "update_patient"]),
    ...mapActions("users", ["request_users"]),
    ...mapActions("modals", ["showModalForm", "closeModalForm"]),
    ...mapActions(["doRequestConfigProperty"]),
    ...mapActions(["doGetUser"]),

    loadPatient() {
      if (this.patient_id) this.request_patients(`id=${this.patient.id}`);
    },

    hasHoursPassed(timeoutStart) {
      if (timeoutStart === undefined) return true;
      const currentTime = Date.now();
      const timeDifference = currentTime - timeoutStart;
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      return hoursDifference >= this.configProperty("patientSessionTimeout");
    },
    showSessionDialog() {
      if (this.patient.visits.length === 0) return;
      if (!this.hasHoursPassed(this.patient.timeout))
        return this.$refs.error_wrapper.showErrorMessage(
          "Ошибка",
          "Для просмотра новой сессии необходимо подождать " +
            this.remainingTime
        );

      const item = this.patient.visits[0];

      //console.log(item);
      this.openedSession = item.id;

      this.showModalForm({
        title: item.product_name,
        component: SessionForm,
        width: this.$vuetify.breakpoint.smAndUp ? "50%" : "80%",
        item,
        patient_id: this.user.Patient.id,
        saveMethod: (item) => {
          return this.update_patient({
            id: this.patient.id,
            data: {
              ...this.patient.data,
              visits: {
                ...this.patient?.data?.visits,
                active: [
                  ...(this.patient?.data?.visits?.active || []).filter(
                    (v) => v.id !== item.id
                  ),
                  {
                    ...item,
                  },
                ],
                viewed: [...(this.patient?.data?.visits?.viewed || [])],
              },
            },
          }).then(() => this.loadPatient());
        },
        archiveItem: () => {
          item.complete_reason = "Просмотрено";
          this.archiveItem(item);
        },
      }).then((payload) => {
        console.log(payload);
      });
    },
    archiveItem(item) {
      item.complete_date = `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
      this.update_patient({
        id: this.patient.id,
        data: {
          ...this.patient.data,
          visits: {
            active: [
              ...(this.patient?.data?.visits.active.filter(
                (v) => v.id !== item.id
              ) || []),
            ],
            viewed: [...(this.patient?.data?.visits.viewed || []), item],
          },
          timeout: Date.now(),
        },
      }).then(() => this.doGetUser());
    },
    async checkSession() {
      await this.doGetUser();

      const sessionExist = this.patient.visits.some(
        (session) => session.id === this.openedSession
      );

      if (!sessionExist) {
        this.closeModalForm();
      }
    },
  },
  beforeMount() {
    this.request_users();
    this.doRequestConfigProperty("patientSessionTimeout");
    this.checkSessionInterval = setInterval(() => {
      this.checkSession();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.checkSessionInterval);
  },
};
</script>
<style scoped lang="scss">
.toolbar-content {
  background-color: #f0f8f6;
  color: #d86568 !important;
}
</style>
